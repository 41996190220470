import request from '@/utils/request.js';

const url = "/manange/system/promote/message";

//获取列表数据
export function listPage(query) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: query
    })
}

/**
 * 获取选项
 */
export function options() {
    return request({
        url: `${url}/options`,
        method: 'get'
    })
}

/**
 * 重启任务
 * @param id
 */
export function restart(id) {
    return request({
        url: `${url}/restart?id=${id}`,
        method: 'put'
    })
}
