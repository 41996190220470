<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true">
      <el-form-item label="任务名称" prop="queueName">
        <el-select v-model="queryParams.queueName" size="mini">
          <el-option v-for="item in options" :value="item.queue" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="任务结果" prop="status">
        <el-select v-model="queryParams.status" size="mini">
          <el-option :value="1" label="成功"></el-option>
          <el-option :value="2" label="失败"></el-option>
          <el-option :value="9" label="已重启"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="dataList" max-height="600"
              align="center">
      <el-table-column label="任务ID" prop="id" align="center" width="100"/>
      <el-table-column label="任务" align="center" prop="queueName">
        <template v-slot="scope">
          <span v-for="item in options" v-if="item.queue === scope.row.queueName"> {{ item.label }}</span>
        </template>
      </el-table-column>
      <el-table-column label="执行结果" prop="status" align="center" width="100">
        <template v-slot="scope">
          <span v-if="scope.row.status === 0">已执行</span>
          <span v-if="scope.row.status === 1">成功</span>
          <span v-if="scope.row.status === 2">失败</span>
          <span v-if="scope.row.status === 9">已重试</span>
        </template>
      </el-table-column>
      <el-table-column label="失败原因" prop="cause" align="center"/>
      <el-table-column label="执行时间" prop="createTime" align="center"/>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="scope">
          <el-button size="mini" type="info" @click="showMessage(scope.row)">查看详情</el-button>
          <el-button size="mini" type="primary" @click="restart(scope.row.id)">重启任务</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.current"
        :limit.sync="queryParams.size"
        @pagination="getList"
    />

    <el-dialog title="任务详情" width="80%" :visible.sync="messageShow">
      <span>{{ messageInfo.cause }}</span>
      <span>{{ messageInfo.createTime}}</span>
      <textarea style="width: 100%;" disabled rows="50">{{
          messageInfo.message
        }}
      </textarea>
    </el-dialog>
  </div>
</template>

<script>
import * as promoteApi from '@/api/system/promoteLog'

export default {
  name: "PromoteLog",
  data() {
    return {
      messageInfo: {},
      messageShow: false,
      options: [],
      dataList: [],
      loading: false,
      total: 0,
      queryParams: {
        current: 1,
        size: 10,
        status: 2,
      }
    }
  },
  methods: {
    showMessage(row) {
      this.messageInfo = row
      this.messageShow = true
    },
    resetQuery() {
      this.queryParams = {
        current: 1,
        size: 10,
        status: 2
      }
    },
    restart(id) {
      this.loading = true
      promoteApi.restart(id).then(res => {
        this.$message.success(res.msg)
        this.getList()
      })
    },
    getOptions() {
      promoteApi.options().then(res => {
        this.options = res
      })
    },
    getList() {
      promoteApi.listPage(this.queryParams).then(res => {
        if (res.success) {
          this.dataList = res.data.data
          this.total = res.data.total
          this.loading = false
        }
      })
    }
  },
  created() {
    this.getOptions()
    this.getList()
  }
}
</script>

<style scoped>

</style>
